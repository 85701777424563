import BackgroundImage from 'gatsby-background-image';
import Check from '../assets/check.svg';
import InputBottomBorder from '../assets/input-bottom-border.inline.svg';
import InputTopBorder from '../assets/input-top-border.inline.svg';
import No1Svg from '../assets/N01.inline.svg';
import No2Svg from '../assets/N02.inline.svg';
import No4Svg from '../assets/N04.inline.svg';
import No3Svg from '../assets/N03.inline.svg';
import React from 'react';
import TextAreaBottomBorder from '../assets/textarea-bottom-border.inline.svg';
import TextAreaTopBorder from '../assets/textarea-top-border.inline.svg';
import { darken } from 'polished';
import posed from 'react-pose';
import styled from 'styled-components';
import theme from './theme';

export const Title1 = styled.h1`
  color: ${props => props.theme.colors.white};
  font-size: 4rem;
  line-height: 4.5rem;
  text-align: left;

  @media ${props => props.theme.breakpoints.md} {
    font-size: 4.5rem;
    line-height: 5rem;
  }
`;

export const Title2 = styled.h2`
  color: ${props => props.theme.colors.white};
  font-size: 3rem;
  line-height: 3.5rem;
  text-align: left;
  overflow-wrap: break-word;

  @media ${props => props.theme.breakpoints.md} {
    font-size: 3.2rem;
    line-height: 3.7rem;
  }
`;

export const Text1 = styled.div`
  color: ${props => props.theme.colors.cloud};
  font-size: 2rem;
  font-family: ${props => props.theme.fonts.sansSerif};
  line-height: 2.5rem;

  @media ${props => props.theme.breakpoints.md} {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
`;

export const Text2 = styled(Text1)`
  @media ${props => props.theme.breakpoints.md} {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
`;

export const BackgroundSection = ({ className, imageData, children }) => (
  <BackgroundImage Tag='section' className={className} fluid={imageData} backgroundColor={`transparent`}>
    {children}
  </BackgroundImage>
);

export const BgContainer = styled(BackgroundSection)`
  display: inline-block;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 40% center;
  transition: background-image 1s ease-in-out;
  width: 100%;
  height: auto;
  padding-top: 7rem;
  padding-bottom: 7rem;

  @media ${props => props.theme.breakpoints.md} {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
`;

export const Container = styled.div`
  margin: 0 auto;
  max-width: 120rem;
  padding-right: 3.8rem;
  padding-left: 3.8rem;
`;

const StyledButton = styled.button`
  display: block;
  font-family: Barlow, sans-serif;
  color: #fff;
  font-weight: normal;
  font-size: 2.2rem;
  padding: 1rem 5.5rem 1.4rem;
`;

export const Button = posed(StyledButton)({
  hoverable: true,
  pressable: true,
  init: { scale: 1, background: theme.colors.meteor },
  press: { scale: 1.07 },
  hover: { background: darken(0.05, theme.colors.meteor) },
  transition: { duration: 500, ease: 'easeInOut' },
});

const StyledInput = styled.input`
  display: inline-block;
  height: 4rem;
  width: 100%;
  font-size: 1.8rem;
  font-weight: bold;
  font-family: Barlow, sans-serif;
  border-left: 0.1rem solid ${props => props.theme.colors.schist};
  border-right: 0.1rem solid ${props => props.theme.colors.schist};
  padding-left: 1.5rem;
`;

const InputContainer = styled.div`
  display: block;
  margin-bottom: 0.8rem;
`;

const inputBorderCss = `
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  float: left;
`;

const StyledInputTopBorder = styled(InputTopBorder)`
  ${inputBorderCss};
  margin-bottom: -0.1rem;
`;

const StyledInputBottomBorder = styled(InputBottomBorder)`
  ${inputBorderCss};
  margin-top: -0.1rem;
`;

export const Input = props => (
  <InputContainer>
    <StyledInputTopBorder width='100%' height='0.2rem' />
    <StyledInput {...props} />
    <StyledInputBottomBorder width='100%' height='0.2rem' />
  </InputContainer>
);

export const Label = styled.label`
  display: block;
  width: 100%;
`;

/* TEXTAREA */

const textareaBorderCss = `
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  float: left;
`;

const StyledTextAreaTopBorder = styled(TextAreaTopBorder)`
  ${textareaBorderCss};
  margin-bottom: -0.2rem;
`;

const StyledTextAreaBottomBorder = styled(TextAreaBottomBorder)`
  ${textareaBorderCss};
  margin-top: -0.2rem;
`;

const StyledTextArea = styled.textarea`
  display: inline-block;
  color: ${props => props.theme.colors.kelp};
  width: 100%;
  font-size: 1.8rem;
  font-weight: normal;
  font-family: Barlow, sans-serif;
  border-left: 0.1rem solid ${props => props.theme.colors.schist};
  border-right: 0.1rem solid ${props => props.theme.colors.schist};
  padding: 1.5rem;
`;

export const TextArea = props => (
  <InputContainer>
    <StyledTextAreaTopBorder width='100%' height='0.4rem' />
    <StyledTextArea {...props} />
    <StyledTextAreaBottomBorder width='100%' height='0.4rem' />
  </InputContainer>
);

/* NUMBERS */

export const No1 = styled(No1Svg)`
  margin-bottom: 4rem;
`;

export const No2 = styled(No2Svg)`
  margin-bottom: 4rem;
`;

export const No4 = styled(No4Svg)`
  margin-bottom: 4rem;

  @media ${props => props.theme.breakpoints.md} {
    text-align: left;
  }
`;

export const No3 = styled(No3Svg)`
  //margin-bottom: 4rem;

  @media ${props => props.theme.breakpoints.md} {
    text-align: left;
  }
`;

/* CHECKBOX */

const CheckboxCustom = styled.div`
  display: flex;
  float: left;
  flex-shrink: 0;
  background: ${props => props.theme.colors.meteor};
  border-radius: 0.3rem;
  height: 1.6rem;
  width: 1.6rem;
  justify-content: space-around;
  align-items: center;
  margin-right: 0.8rem;
  margin-top: 0.3rem;

  &:after {
    content: url(${Check});
    opacity: 0;
    margin-top: -0.5rem;
  }
`;

const CheckboxInput = styled.input`
  opacity: 0;
  position: absolute;

  &:checked {
    ~ ${CheckboxCustom} {
      &:after {
        opacity: 1;
      }
    }
  }
`;

const CheckboxContainer = styled.div`
  display: block;
  color: ${props => props.theme.colors.kelp};
  font-size: 1.8rem;
  font-weight: normal;
  font-family: Barlow, sans-serif;
`;

const CheckboxLabel = styled.label`
  display: block;
  width: 100%;
  text-align: left;
`;

const CheckboxText = styled.span``;

export const Checkbox = ({ children, name, ...rest }) => (
  <CheckboxContainer>
    <CheckboxLabel htmlFor={name}>
      <CheckboxInput name={name} id={name} type='checkbox' {...rest} />
      <CheckboxCustom />
      <CheckboxText>{children}</CheckboxText>
    </CheckboxLabel>
  </CheckboxContainer>
);
